document.body.addEventListener('click', detectGtmEvent)

/**
 * Проверяет DOM-узел на совпадение с селектором.
 *
 * @param {Element|EventTarget} el DOMElement
 * @param {string} selector проверяемый селектор
 *
 * @return {boolean} соответствует ли узел селектору
 */
function selectorMatches(el, selector) {
  const p = Element.prototype
  const f = p.matches ||
      p.webkitMatchesSelector ||
      p.mozMatchesSelector ||
      p.msMatchesSelector ||
      p.oMatchesSelector ||
      function (s) {
        return [].indexOf.call(document.querySelectorAll(s), this) !== -1
      }

  return f.call(el, selector)
}

/**
 * Полифил для closest и matches
 */
(function (ELEMENT) {
  ELEMENT.closest = ELEMENT.closest || function closest(selector) {
    if (!this) {
      return null
    }

    if (selectorMatches(this, selector)) {
      return this
    }

    if (!this.parentElement) {
      return null
    } else {
      return this.parentElement.closest(selector)
    }
  }
}(Element.prototype))

/**
 * Отправка событий в GTM
 *
 * @param {Event} event событие отправки
 *
 * @return {boolean} результат события
 */
function detectGtmEvent(event) {
  if (typeof event.target === 'undefined') {
    return false
  }

  const el = event.target

  if (selectorMatches(el, '.search-result__floor .js-item-link')) {
    searchProductClickGtmEvent($(el))

    return true
  }

  const searchItem = el.closest('.js-item-link')
  if (searchItem !== null) {
    searchProductClickGtmEvent($(searchItem))

    return true
  }
}

function searchProductClickGtmEvent($product) {
}

function getProductFromItemData(itemData) {
  const prices = $.grep(itemData.prices, function (price) {
    return (String(price.shipper_id) === String(itemData.shipper_id))
  })
  const product = {
    name    : itemData.name,
    id      : itemData.number,
    brand   : itemData.manufacturer,
    quantity: itemData.quantity
  }

  if (prices.length === 1) {
    product.price = prices[0].price
  }

  return product
}

function pushImpressionEvent(products) {
}

window.productClickGtmEvent = function ($product, position) {
  const data = $product.data('item')
  const product = {
    name    : data.name,
    id      : data.number,
    brand   : data.manufacturer,
    category: data.tech_type,
    list    : getProductList(data.number, data.manufacturer, 'Category'),
    variant : data.variant,
    position: position
  }
}

window.addToCartGtmEvent = function (payload) {
  const event = new window.gtmEvents.AddToCartEvent()

  event.dispatch(payload)
}

window.removeFromCartGtmEvent = function ($offer) {
}

/**
 * Возвращает данные о товарах в корзине.
 *
 * @return {Array} данные товаров
 */
window.getCartProducts = function () {
  const products = []
  $('.cart-list__row').each(function (i, row) {
    const itemData = $(row).data('item')
    const product = getProductFromItemData(itemData)

    products.push(product)
  })

  return products
}

window.secondStepCartGtmEvent = function () {
}

window.catalogImpressionGtmEvent = function () {
  const products = []
  $('.js-items-list-item').each(function (i, item) {
    const data = $(item).data('item')

    products.push({
      name    : data.name,
      id      : data.number,
      brand   : data.manufacturer,
      category: data.tech_type,
      list    : getProductList(data.number, data.manufacturer, 'Category'),
      variant : data.variant,
      position: item.getAttribute('data-list-counter') || 1
    })
  })

  pushImpressionEvent(products)
}

window.searchListImpressionGtmEvent = function () {
  let position = 1
  const products = []
  $('.js-item-link:lt(10)').each(function (i, item) {
    const data = $(item).data('item')

    products.push({
      name    : data.name,
      id      : data.number,
      brand   : data.manufacturer,
      list    : getProductList(data.number, data.manufacturer, 'Search'),
      position: position++
    })
  })

  pushImpressionEvent(products)
}

window.sendProductDetailsGtmEvent = function () {
  const $item = $('.item__price-block')
  const data = $item.data('offer')
  const product = {
    name : data.name,
    id   : data.number,
    brand: data.manufacturer
  }
  const impression = $.extend({ position: 1, list: getProductList(data.number, data.manufacturer, data.list) }, product)

  if (data.price !== false && data.price > 0) {
    product.price = data.price
  }
}

function getProductList(id, brand, list) {
  if (!window.isLocalStorageAvailable()) {
    return list
  }

  const key = id + '_' + brand
  let value = localStorage.getItem(key)

  if (value === null) {
    try {
      localStorage.setItem(key, list)
    } catch (e) {
      localStorage.clear()
      localStorage.setItem(key, list)
    }
    value = list
  }

  return (['Category', 'Search', 'Item'].indexOf(value) !== -1 ? value : list)
}
